import Axios from "axios"
import { ErrorMessage, Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import "../components/i18n"
import Layout from "../components/layout"
import Loader from "../components/loader"
import SEO from "../components/seo"
import "../styles/main.scss"
import Fade from "react-reveal/Fade"
import "semantic-ui-css/components/dimmer.min.css"
import "semantic-ui-css/components/modal.min.css"
import "semantic-ui-css/components/transition.min.css"
import { Modal } from "semantic-ui-react"

const ResetPassword = ({ location }) => {
  let resetForm = undefined
  const [visible, setVisible] = useState(false)
  const { t, ready } = useTranslation("resetPassword")
  const Schema = Yup.object().shape({
    password: Yup.string().min(6, t("minError")).required(t("requiredError")),
    repeatPassword: Yup.string()
      .min(6, t("minError"))
      .required(t("requiredError"))
      .test("equal", t("equalError"), function (v) {
        // Don't use arrow functions
        return v === this.options.parent.password
      }),
  })

  const submit = values => {
    Axios({
      method: "post",
      url: process.env.GATSBY_STRAPI_HOST.concat("/auth/reset-password"),
      data: {
        code:
          location?.href.includes("code=") && location.href.split("code=")[1],
        password: values.password,
        passwordConfirmation: values.repeatPassword,
      },
    })
      .then(response => {
        if (response.status === 200) {
          resetForm && resetForm()
          openModal()
        }
      })
      .catch(err => {
        console.log(err, "error")
      })
  }

  const closeModal = () => setVisible(false)
  const openModal = () => setVisible(true)

  return !ready ? (
    <Loader />
  ) : (
    <Layout withBg>
      <SEO title="Home" />
      <div className="col center margin_top--large">
        <Formik
          initialValues={{
            password: "",
            repeatPassword: "",
          }}
          validationSchema={Schema}
          onSubmit={values => submit(values)}
        >
          {props => {
            resetForm = props.resetForm
            return (
              <Form className="form">
                <Field
                  aria-label={t("password")}
                  id="password"
                  className="input"
                  type="password"
                  name="password"
                  placeholder={t("password")}
                />
                <Fade bottom collapse>
                  <ErrorMessage
                    component="span"
                    className="error_text"
                    name="password"
                  />
                </Fade>

                <Field
                  aria-label={t("repeatPassword")}
                  id="repeatPassword"
                  className="input"
                  type="password"
                  name="repeatPassword"
                  placeholder={t("repeatPassword")}
                />
                <Fade bottom collapse>
                  <ErrorMessage
                    component="span"
                    className="error_text"
                    name="repeatPassword"
                  />
                </Fade>

                <button type="submit" className="button margin_top--big">{t("submit")}</button>
              </Form>
            )
          }}
        </Formik>
      </div>

      <Modal size="mini" open={visible} onClose={closeModal}>
        <Modal.Header>{t("successfullySent")}</Modal.Header>

        <Modal.Actions>
          <button onClick={closeModal} className="button">
            {t("close")}
          </button>
        </Modal.Actions>
      </Modal>
    </Layout>
  )
}

export default ResetPassword
